import { Layout } from "antd";
import { PageProps } from "layout";

import PageLoader from "components/loading";

import useCheckIsUserAuthenticated from "hooks/authenticated";

const Index: PageProps = () => {
  const loading = useCheckIsUserAuthenticated();

  if (loading) {
    return <PageLoader />;
  }

  return <Layout.Content />;
};

export default Index;
Index.Layout = "Blank";
